<template>
    <div class="setting" v-loading="bxLoading">
        <el-form :model="form" :rules="rules" ref="ruleForm">
            <el-tabs v-model="activeName" >
                <el-tab-pane label="网站设置" name="base">
                    <el-row :gutter="20">
                        <el-col>
                            <el-form-item label="网站标题" prop="site_title">
                                <el-input v-model="form.site_name"></el-input>
                            </el-form-item>
                            <el-form-item label="结果页文字提示" prop="site_title">
                                <el-input v-model="form.result_tips"></el-input>
                            </el-form-item>


                            <el-form-item label="结果页文件" prop="result_files" label-width="100">
                                <el-upload
                                        class="upload-demo"
                                        :action="action"
                                        :on-preview="handlePreview"
                                        :on-remove="function (file, type){return handleRemove(file, 'result_files')}"
                                        :file-list="result_files"
                                        style="width: 100%"
                                        :limit="1"
                                        :data="{field: 'result_files'}"
                                        :multiple="false"
                                        :on-success="handleSuccess"
                                        accept=".docx, .doc, .txt, .zip, .rar, .pdf"
                                        list-type="picture">
                                    <el-button size="small" type="primary">点击上传</el-button>
                                    <div slot="tip" class="el-upload__tip">不超过5MB</div>
                                </el-upload>
                            </el-form-item>

                            <hr>

                            <el-form-item label="网站Logo" prop="site_logo" label-width="100">
                                <el-upload
                                        class="upload-demo"
                                        :action="action"
                                        :on-preview="handlePreview"
                                        :on-remove="function (file, type){return handleRemove(file, 'fileList')}"
                                        :file-list="fileList"
                                        style="width: 100%"
                                        :limit="1"
                                        :data="{field: 'site_logo'}"
                                        :multiple="false"
                                        :on-success="handleSuccess"
                                        accept=".jpg, .jpeg, .png"
                                        list-type="picture">
                                    <el-button size="small" type="primary">点击上传</el-button>
                                    <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
                                </el-upload>
                            </el-form-item>
                            <el-form-item label="公用秘籍" prop="mijifile" label-width="100">
                                <el-upload
                                        class="upload-demo"
                                        :action="action"
                                        :on-preview="handlePreview"
                                        :on-remove="function (file, type){return handleRemove(file, 'mijifileList')}"
                                        :file-list="mijifileList"
                                        style="width: 100%"
                                        :limit="1"
                                        :data="{field: 'mijifile'}"
                                        :multiple="false"
                                        :on-success="handleSuccess"
                                        accept=".docx, .doc, .txt, .zip, .rar, .pdf, .html, .url"
                                        list-type="picture">
                                    <el-button size="small" type="primary">点击上传</el-button>
                                    <div slot="tip" class="el-upload__tip">不超过5MB</div>
                                </el-upload>
                            </el-form-item>
                            <el-form-item label="网站关键词" prop="site_keyword">
                                <el-input v-model="form.site_keyword"></el-input>
                            </el-form-item>
                            <el-form-item label="网站描述" prop="site_description">
                                <el-input type="textarea" autosize v-model="form.site_description"></el-input>
                            </el-form-item>
                            <el-form-item label="网站版权信息" prop="site_copyright">
                                <el-input type="textarea" autosize v-model="form.site_copyright"></el-input>
                            </el-form-item>
                            <el-form-item label="后台分页每页显示数据" prop="page">
                                <el-input v-model="form.page"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-tab-pane>
                <el-tab-pane label="展示信息管理" name="info">
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-form-item label="网页客服" prop="wykf">
                                <el-input v-model="form.wykf"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="旺旺客服" prop="ww_kf">
                                <el-input v-model="form.ww_kf"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="QQ客服" prop="qq_kf">
                                <el-input v-model="form.qq_kf"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="微信客服" prop="wechat">
                                <el-input v-model="form.wechat"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="邮箱" prop="email">
                                <el-input v-model="form.email"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="官网验证地址" prop="gw_url">
                                <el-input v-model="form.gw_url"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="对比库范围描述">
                                <el-input v-model="form.dbk"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="字符统计描述">
                                <el-input v-model="form.zftj"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="检测报告描述">
                                <el-input v-model="form.jcbg"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="重要提醒描述" prop="tips">
                                <el-input v-model="form.tips"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="电话联系" prop="tel">
                                <el-input v-model="form.tel"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="淘宝店铺" prop="tel">
                                <el-input v-model="form.tb_url"></el-input>
                            </el-form-item>
                        </el-col>

                    </el-row>
                </el-tab-pane>
                <el-tab-pane label="支付方式设置" name="payment_mode">
                    <el-row :gutter="20">
                        <el-col v-for="mode in paymentMode" :key="mode.id">
                            <div :label="mode.name" :prop="mode.flag" >
                                <div class="payment">
                                    <div>
                                        {{ mode.payment_name }}
                                    </div>
                                    <div v-if="is_default">
                                        <el-radio
                                                @change="changeDefault(mode)"
                                                :data-value="is_default"
                                                v-model="is_default" name="default" :label="mode.id" :data-label="mode.id">默认选中</el-radio>
                                        <el-switch
                                                v-model="mode.status"
                                                :active-value="1"
                                                @change="changeStatus(mode)"
                                                >
                                        </el-switch>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </el-tab-pane>
                <el-col :span="24">
                    <el-form-item>
                        <el-button type="primary" :loading="loading" @click="handleUpdate">更新</el-button>
                    </el-form-item>
                </el-col>
            </el-tabs>
        </el-form>
    </div>
</template>

<script>
    import {getSetting, updateSetting, deleteLogoFile} from '@/api/setting'
    import {mapGetters} from 'vuex'
    import {getPaymentMode} from "@/api/payment-mode";
    import {updatePaymentDefault, updatePaymentStatus} from "../../api/payment-mode";
    export default {
        name: "setting",
        data() {
            return {
                activeName: 'base',
                form: {},
                loading: false,
                bxLoading: true,
                rules: {
                    ww_kf: [
                        {required: true, message: '请输入旺旺客服', trigger: 'blur'},
                    ],
                    qq_kf: [
                        {required: true, message: '请输入QQ客服', trigger: 'blur'},
                    ],
                    email: [
                        {required: true, type: 'email', message: '请输入邮箱', trigger: 'blur'},
                    ],
                    tips: [
                        {required: true, message: '请输入提示', trigger: 'blur'},
                    ],
                },
                fileList: [],
                mijifileList: [],
                result_files: [],
                paymentMode: [],
                is_default: null
            }
        },
        mounted() {
            this.getSettingData()
            this.getPaymentModes()
        },
        computed: {
            ...mapGetters({
               baseImageUrl : 'setting/baseImageUrl'
            }),
            // eslint-disable-next-line vue/return-in-computed-property
            action() {
                return  process.env.VUE_APP_BASE_API + '/admin/setting/uploader'
            },
        },
        watch: {
        },
        methods: {
            // eslint-disable-next-line no-unused-vars
            handleClick(curr) {
                // console.log(curr)
            },
            async changeDefault(mode) {
                const res = await updatePaymentDefault(mode)
                this.is_default = mode.id
                if(res.code === 20000){
                    this.$message.success(res.message);
                }
            },
            async changeStatus(mode) {
                if(this.is_default == mode.id){
                    mode.status = 1;
                    return this.$message.error('请先切换默认支付为正在启用的支付方式')
                }
                const res = await updatePaymentStatus(mode)
                if(res.code === 20000){
                    this.$message.success(res.message);
                }
            },
            async getSettingData() {
                const rsp = await getSetting();
                this.form = rsp.data;
                this.fileList = this.form.site_logo ? [{name: this.form.site_logo, url: this.baseImageUrl + this.form.site_logo}] : [];
                this.mijifileList = this.form.mijifile ? [{name: this.form.mijifile, url: this.baseImageUrl + this.form.mijifile}] : [];
                this.result_files = this.form.result_files ? [{name: this.form.result_files, url: this.baseImageUrl + this.form.result_files}] : [];
                this.bxLoading = false;
            },
            async getPaymentModes() {
                const {data} = await getPaymentMode()
                this.paymentMode = data
                this.is_default = data.filter((mode) => {
                    return mode.is_default == 1
                })[0].id
            },
            handleSuccess(rsp){
                if(typeof rsp.field !== "undefined"){
                     this.form[rsp.field] = rsp.name;
                     if(rsp.field === 'site_logo'){
                         this.fileList.push(rsp);
                     }
                    if(rsp.field === 'mijifile'){
                        this.mijifileList.push(rsp);
                    }
                }
            },
            handlePreview() {

            },
            async handleRemove(file, filelist) {
                if(filelist === 'fileList'){
                    this.fileList = [];
                }
                if(filelist === 'mijifileList'){
                    this.mijifileList = [];
                }
                if(filelist === 'result_files'){
                    this.result_files = [];
                }
                await deleteLogoFile({file: file.name});
                this.$message.success('删除成功');

            },
            handleUpdate() {
                this.loading = true;
                this.$refs['ruleForm'].validate( async (valid) => {
                    if (valid) {
                        const rsp = await updateSetting(this.form);
                        if (rsp.code === 20000) {
                            this.loading = false;
                            this.$message.success('更新成功!')
                        }
                    } else {
                        this.$message.error('error submit!!');
                        this.loading = false;
                        return false;
                    }
                });

                this.loading = false;

            }
        }
    }
</script>

<style scoped>
    .setting {
        background: #fff;
        padding: 15px
    }
    .payment{
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid #eee;
        display: flex;
        justify-content: space-between;
    }
</style>