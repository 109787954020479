import {get, post} from '@/utils/http'

export function getSetting() {
    return get({
        url: 'admin/setting',
        method: 'get'
    })
}

export function updateSetting(data) {
    return post({
        url: 'admin/setting',
        method: 'post',
        params: data
    })
}

export function deleteLogoFile(data) {
    return post({
        url: 'admin/delete/file',
        method: 'post',
        params: data
    })
}