import { post, get } from "@/utils/http";


export function getPaymentMode() {
    return get({
        url:'admin/payment-mode',
        method: 'get',
    })
}

export function updatePaymentStatus(mode) {
    return post({
        url: 'admin/payment-mode',
        method: 'patch',
        params: {
            ...mode,
            _method: 'PATCH'
        }
    })
}
export function updatePaymentDefault(mode) {
    return post({
        url: 'admin/payment-mode/default',
        method: 'patch',
        params: {
            ...mode,
            _method: 'PATCH'
        }
    })
}